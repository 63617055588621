<template>
  <v-row>
    <v-col cols="12">
      <v-form ref="form" v-model="valid" validation>
        <base-card>
        <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              top
              color="primary"
          ></v-progress-linear>
          <v-card-title>New Patner</v-card-title>
          <v-divider></v-divider>
          <div class="pa-10">
            <v-row>
              <v-col cols="12">
                <v-text-field
                    dense
                    required
                    :rules="[(v) => !!v || 'Name is required']"
                    v-model="formData.name"
                    label="Name"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-select 
                  :rules="[(v) => !!v || 'Item is required']"
                  required
                  v-model="formData.countryId"
                  dense
                  :items="getCountryList"
                  item-text="name"
                  item-value="countryId"
                  label="Country" />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                    dense
                    required
                    :rules="[(v) => !!v || 'City is equired']"
                    v-model="formData.city"
                    label="City"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                    dense
                    required
                    :rules="[(v) => !!v || 'Address is required']"
                    v-model="formData.postalAddress"
                    label="Postal Address"
                />
              </v-col>
              
              <v-col cols="12" md="4">
                <v-text-field
                    dense
                    required
                    :rules="[(v) => !!v || 'Name is required']"
                    v-model="formData.physicalAddress"
                    label="Physical Address"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                    dense
                    required
                    :rules="[(v) => !!v || 'City is equired']"
                    v-model="formData.phone"
                    label="Phone"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                    dense
                    required
                    :rules="[(v) => /.+@.+/.test(v) || 'E-mail must be valid']"
                    v-model="formData.email"
                    label="Email"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                    dense
                    v-model="formData.contactperson"
                    label="Contact Person"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                    dense
                    v-model="formData.description"
                    :rows="2"
                    :row-height="20"
                    name="input-7-4"
                    label="Description"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                    v-model="formData.iscustomer"
                    label="Customer."
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                    v-model="formData.isvendor"
                    label="Vendor."
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                    v-model="formData.isemployee"
                    label="Employee."
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                    v-model="formData.issalesrep"
                    label="Sales Representative."
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                    v-model="formData.isprospect"
                    label="Prospect."
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                    v-model="formData.isonetime"
                    label="One Time Patner."
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <v-card-actions class="px-10 py-4">
            <v-spacer></v-spacer>
            <v-btn
            color="primary"
            :disabled="!valid"
            @click="submitPatner()"
            >
            Submit
            </v-btn>
          </v-card-actions>
        </base-card>
      </v-form>
    </v-col>
  </v-row>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "PatnerForm",

  data: () => ({
    valid: false,
    formData: {
      bpartnerId: 0,
      value: '',
      organizationId: 0,
      name: "",
      postalAddress: "",
      physicalAddress: "",
      contactperson: "",
      phone: "",
      email: "",
      city: "",
      countryId: 0,
      description: "",
      isonetime: false,
      isprospect: true,
      isvendor: true,
      iscustomer: true,
      isemployee: false,
      issalesrep: false,
      active: true
    }
  }),
  watch: {
    patnerSnackbarMessage (val) {
      if (val != null) {
        this.makeToast("success", "Successfully added patner");
        console.log('snacked in successfully ')
        setTimeout(() => {
            
        }, 2000)
      }
    },
    partner (val) {
      if (val != null) {
        // this.makeToast("success", "Successfully added patner");
        console.log('snacked in successfully ')
        setTimeout(() => {
            this.$router.push('/patner')
        }, 100)
      }
    },
  },
  mounted () {
    this.fetchCountries()
  },
  computed: {
    ...mapGetters(['loading', 'loggedInUser', 'partner', 'patnerSnackbarMessage', 'getCountryList'])
  },
  methods: {
    ...mapActions(['fetchCountries', 'togglePatnerSnackbarMessage', 'createPatner']),
    submitPatner () {
      this.formData.organizationId = this.loggedInUser.organizations[0].id
      this.createPatner(this.formData)
    }
  }
};
</script>
